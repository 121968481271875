<template>
    <div v-bind:style="styles" class="pgwrapper" id="pgwrapper">
     
     
     <div id="page-content" >
        <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px">Academic Renewal</h1>
        <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div> -->
      <div class="webNew-dispflex">
       
       <!-- <div class="backbox">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Academic Renewal
       </h4>
     </div>
      <div class="chat-bar-container">
    <div class="chat-bar" id="chatBar"></div>
    <div id="percentage">0%</div>
</div>
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group col-md-12 mt-3 pr-md-3">
                <div class="tbllist mt-4">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Checklist</div>

                      <div class="cell">Page Link</div>
                      <div class="cell">Mark as Completed</div>
                    </div>
                    <div
                      class="resrow"
                      v-for="(data, index) in newList"
                      :key="index"
                    >
                      <div class="cell" data-title="Teacher">
                        {{ data.label }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="customNavigate(data.link)"> {{ data.link }} </a>

                       
                      </div>
                      <div class="cell" data-title="Teacher">
                        <div class="cell">
                          <label class="switch" style="margin-bottom: -0.5rem ">
                            <input
                              type="checkbox"
                              @change="setAcademicValues(data.value, data.isCompleted)"
                              id="togBtn"
                              v-model="data.isCompleted"
                            />
                            <div class="slider"></div>
                          </label>
                        </div>

                      
                      </div>

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
               
              </div>
            </div>
          </form>
        </div>
      </div>
     
</template>
<script>
import Select from "vue-select";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  components: {
    "v-select": Select,
  },
 
  data() {
    return {
      isAdmin: false,
      activeSubscriptions: [],
      academicDetails: {
        academicYear: false,
        promoteStudents: false,
        termsAndGradeSettings: false,
        createAndUpdateSubjects: false,
        assignAndReassignTeachers: false,
        createClass: false,
        feePlan: false,
        publishFeePlans: false
      },
      newList: [],
      renewalList: [
        {
            "label": "Create a New Academic Year",
            "value": "academicYear",
            "link" :  "School Hub",
            "isCompleted" : false
        },
        {
            "label": "Promote Students",
            "value": "promoteStudents",
            "link" :  "Academic Renewal",
            "isCompleted" : false
        },
        {
            "label": "Set your Terms & Grade Settings",
            "value": "termsAndGradeSettings",
            "link" :  "Grade & Terms",
            "isCompleted" : false
        },
        {
            "label": "Create/Update the Subjects for each class",
            "value": "createAndUpdateSubjects",
            "link" :  "Class | rooms",
            "isCompleted" : false
        },
        {
            "label": "Assign/Re-assign the Teachers",
            "value": "assignAndReassignTeachers",
            "link" :  "School Class | rooms",
            "isCompleted" : false
        },
        {
            "label": "Create Class Timetable",
            "value": "createClass",
            "link" :  "Academic Management",
            "isCompleted" : false
        },
        {
            "label": "Create Fee Plan for Each Class",
            "value": "feePlan",
            "link" :  "Fee Management",
            "isCompleted" : false
        },
        {
            "label": "Publish the Fee Plans to Students",
            "value": "publishFeePlans",
            "link" :  "Fee Management",
            "isCompleted" : false
        }

      ]
    };
  },

  created() {
    this.initComponent();
   // this.getPreviousMonths();
    //this.formDateList();
   // this.getClassWithSection();
  },
 
  
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
   
  },
  methods: {
   
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     // Function to update the chat bar with percentage
     updateChatBar(percentage) {
        var chatBar = document.getElementById("chatBar");
        var percentageDisplay = document.getElementById("percentage");

        chatBar.style.width = percentage + "%";
        percentageDisplay.innerText = percentage + "%";
    },

    // Example usage: Update chat bar with 50% occupied

    async initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        let sss = this.$store.getters.currentPlanDetail;
          
          this.activeSubscriptions = sss || []
          this.newList = []
        const response = await ViService.viXGet(
            `/academic/getAcademicDetails`,
            userData.token
          );
        // const response = await ViService.viXGet(
        //   "/academicYear/getAcademicYearListBranch",
        //   userData.token
        // );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          for(let x of this.renewalList){
            if(x.value == 'academicYear'){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.academicYear || false
              })
             // x.isCompleted = data.academicYear || false
            }
            if(x.value == 'promoteStudents'){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.promoteStudents || false
              })
            }
            if(x.value == 'termsAndGradeSettings' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.termsAndGradeSettings || false
              })
              //x.isCompleted = data.termsAndGradeSettings || false
            }
            if(x.value == 'createAndUpdateSubjects' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.createAndUpdateSubjects || false
              })
            //  x.isCompleted = data.createAndUpdateSubjects || false
            }
            if(x.value == 'assignAndReassignTeachers' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.assignAndReassignTeachers || false
              })
             // x.isCompleted = data.assignAndReassignTeachers || false
            }
            if(x.value == 'createClass' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.createClass || false
              })
             // x.isCompleted = data.createClass || false
            }
            if(x.value == 'feePlan' && this.checkPermission(['V50','VFCP01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.feePlan || false
              })
             // x.isCompleted = data.feePlan || false
            }
            if(x.value == 'publishFeePlans' && this.checkPermission(['V50','VFCP01','VCP'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.publishFeePlans || false
              })
              //x.isCompleted = data.publishFeePlans || false
            }
           
          }
          let ssd = this.newList.filter( x => x.isCompleted)

          const percentage = (ssd.length / this.newList.length) * 100;
          percentage.toFixed(2)
          let roundedNumber = Math.ceil(percentage);

          this.updateChatBar(roundedNumber);

        //  this.academicDetails.: {
          this.academicDetails.academicYear = data.academicYear || false,
          this.academicDetails.promoteStudents  = data.academicYear || false,
          this.academicDetails.termsAndGradeSettings  = data.academicYear || false,
          this.academicDetails.createAndUpdateSubjects  = data.academicYear || false,
          this.academicDetails.assignAndReassignTeachers  = data.academicYear || false,
          this.academicDetails.createClass = data.academicYear || false,
          this.academicDetails.feePlan = data.academicYear || false,
          this.academicDetails.publishFeePlans  = data.academicYear || false
      //},

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    checkPermission(plan){

      const containsSome = this.activeSubscriptions.some(obj => plan.includes(obj.plan_code));
    // let data = this.activeSubscriptions.filter( x => x.plan_code == 'V50' || x.plan_code == 'VFCP01')

    //   if(data && data.length > 0){
        return true
    //   }else{
    //     return false
    //   }
    },
    async setAcademicValues(key,value){
      let obj = {
        [key] : value
      }

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXPost(
            "/academic/updateAcademicDetails",
            obj,
            userData.token
          );

          if (response.isSuccess) {
           this.initComponent()
           this.$toasted.success(response.message);

          } else {
            this.$toasted.error(response.message);
          }
      }
    },

    customNavigate(type){
        if(type == 'School Hub'){
            this.$router.push("/admission/home");
        }
        if(type == 'View Invoices'){
            this.$router.push("/feesManagement/invoice/feePlan");
        }
        if(type == 'Academic Renewal'){
            this.$router.push("/admission/admission/promote/students");
        }
        if(type == 'Grade & Terms'){
            this.$router.push("/admission/term/setting");
        }
        if(type == 'Class | rooms'){
            this.$router.push("/admission/class");
        }
        if(type == 'School Class | rooms'){
            this.$router.push("/admission/class");
        }
        if(type == 'Academic Management'){
            this.$router.push("/academic/academicManagement");
        }
        if(type == 'Fee Management'){
            this.$router.push("/feesManagement/home");
        }
       

    },
   
   
    
   
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
  },
};
</script>
<style>
 .chat-bar-container {
        margin-bottom: -35px ;
        bottom: 0;
        width: 100%;
        background-color: #f2f2f2;
        padding: 10px;
    }
    .chat-bar {
        height: 20px;
        background-color: #4caf50;
        width: 0;
    }
    #percentage {
        text-align: center;
    }
</style>
