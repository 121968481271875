<template>
    <div v-bind:style="styles" class="pgwrapper" id="pgwrapper">
     
     
      <div id="page-content" v-if="!viLoader">
        
       
        <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px">Academic Renewal</h1>
        <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div> -->
      <div class="webNew-dispflex">
       
       <!-- <div class="backbox">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Academic Renewal
       </h4>
     </div>
        
        <!-- <div class="row mt-3">
          <div class="col-sm-6 col-lg-6" @click.prevent="redirectRoute('/admin/AcademicRenewal/action/link')">
            <div class="white-box org mb-2" style="border: 3px solid gainsboro;border-radius: 15px;">
              <div class="no-org no-cirlce">{{ '9 %' }}</div>
              <p class="widgettitle" style="font-size: larger;font-weight: bold;">Academic Renewal Check list</p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-6" @click.prevent="redirectRoute('/admission/promote/students')">
            <div class="white-box blu mb-2" style="border: 3px solid gainsboro;border-radius: 15px;">
              <div class="no-blu no-cirlce">{{ 4 }}</div>
              <p class="widgettitle" style="font-size: larger;font-weight: bold;">Promote Students</p>
            </div>
          </div>
        </div> -->
        <div class="row mt-2">
            <div class="col-md-4">
              <div
                class="white-box m-2 white-box1"
                style="padding: 0"
                @click.prevent="redirectRoute('/admin/AcademicRenewal/action/link')"
              >
                <div class="admcount_lft blu_bg">
                  <!-- <span class="steps">Step 1</span> -->
                  <span class="admcount org_bg">{{ percentageValue }}%</span><span style="display: block;line-height:18px;">Academic Renewal</span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left"></div>
                  Setup and Academic Renewal for Academic Year
                  <!-- Academic Year
                  <span><br />Setup and Conclude the Academic Year session</span> -->
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="white-box m-2 white-box1"
                style="padding: 0"
                @click.prevent="redirectRoute('/admission/promote/students')"
              >
                <div class="admcount_lft blu_bg">
                  <!-- <span class="steps">Step 1</span> -->
                  <span ></span><span style="display: block;line-height:50px;">Promote Students</span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left"></div>
                   Promote Students to Next Academic Year
                  <!-- Academic Year
                  <span><br />Setup and Conclude the Academic Year session</span> -->
                </div>
              </div>
            </div>
           
          </div>
  
  
      </div>
      <vi-spinner
                  v-if="viLoader"
                  text="Loading..."
                  textColor="vi-brand-color"
                  class="flex-fill h-100 vi-fs12"
                  style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
                />
      
    </div>
  </template>
  
  <script>
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import ViSpinner from "../Common/ViSpinner";
  
  export default {
    name: "Dashboard",
  
    data() {
      return {
        viLoader: false,
        allowBarForBoysAndGirls: false,
        percentageValue: 0,
        newList: [],
        renewalList: [
        {
            "label": "Create a New Academic Year",
            "value": "academicYear",
            "link" :  "School Hub",
            "isCompleted" : false
        },
        {
            "label": "Promote Students",
            "value": "promoteStudents",
            "link" :  "Academic Renewal",
            "isCompleted" : false
        },
        {
            "label": "Set your Terms & Grade Settings",
            "value": "termsAndGradeSettings",
            "link" :  "Grade & Terms",
            "isCompleted" : false
        },
        {
            "label": "Create/Update the Subjects for each class",
            "value": "createAndUpdateSubjects",
            "link" :  "Class | rooms",
            "isCompleted" : false
        },
        {
            "label": "Assign/Re-assign the Teachers",
            "value": "assignAndReassignTeachers",
            "link" :  "School Class | rooms",
            "isCompleted" : false
        },
        {
            "label": "Create Class Timetable",
            "value": "createClass",
            "link" :  "Academic Management",
            "isCompleted" : false
        },
        {
            "label": "Create Fee Plan for Each Class",
            "value": "feePlan",
            "link" :  "Fee Management",
            "isCompleted" : false
        },
        {
            "label": "Publish the Fee Plans to Students",
            "value": "publishFeePlans",
            "link" :  "Fee Management",
            "isCompleted" : false
        }

      ]
      };
    },
  
    created() {
      this.initComponent();
    //  // this.getStudentCountDashboardData();
    //  // this.getAdmissionDashboardData();
    //   this.getSchoolDetailDashboardData();
    //   this.getSchoolOverallDetailDashboardData('new');
    //  // this.getNoOfSeatsAdmissionDashboardData()
    //   this.getOverdueAndForeCastAmount()
    //   this.getAcademicYearList();
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
      
    },
  
    methods: {
        previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
    redirectRoute(vipath) {
       let currentRoute = ''
       if(this.$route.path == '/admission/home' || this.$route.path == '/admission/academicYear' || this.$route.path == '/admission/teacher' || this.$route.path == '/admission/department'  || this.$route.path == '/admission/class' || this.$route.path == '/admission/student' || this.$route.path == '/admission/new' || this.$route.path == '/admission/term/setting'){
         currentRoute = '/admission/home'
       }else if(this.$route.path == '/feesManagement/home' || this.$route.path == '/feesManagement/createFeeType' || this.$route.path == '/feesManagement/createPlan' || this.$route.path == '/feesManagement/adhocfees' || this.$route.path == '/feesManagement/paymentGateWay' || this.$route.path == '/feesManagement/invoice/feePlan'){
         currentRoute = '/feesManagement/home'
       }else{
         currentRoute = this.$route.path
       }
       localStorage.setItem("previousTab",currentRoute);
       localStorage.setItem("activeTab",vipath);
       if (this.$route.path !== vipath) this.$router.push({ path: vipath });
 
     },
     checkPermission(plan){

      const containsSome = this.activeSubscriptions.some(obj => plan.includes(obj.plan_code));
    // let data = this.activeSubscriptions.filter( x => x.plan_code == 'V50' || x.plan_code == 'VFCP01')

    //   if(data && data.length > 0){
        return true
    //   }else{
    //     return false
    //   }
    },
     async initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        let sss = this.$store.getters.currentPlanDetail;
          
          this.activeSubscriptions = sss || []
          this.newList = []
        const response = await ViService.viXGet(
            `/academic/getAcademicDetails`,
            userData.token
          );
        // const response = await ViService.viXGet(
        //   "/academicYear/getAcademicYearListBranch",
        //   userData.token
        // );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          for(let x of this.renewalList){
            if(x.value == 'academicYear'){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.academicYear || false
              })
             // x.isCompleted = data.academicYear || false
            }
            if(x.value == 'promoteStudents'){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.promoteStudents || false
              })
            }
            if(x.value == 'termsAndGradeSettings' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.termsAndGradeSettings || false
              })
              //x.isCompleted = data.termsAndGradeSettings || false
            }
            if(x.value == 'createAndUpdateSubjects' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.createAndUpdateSubjects || false
              })
            //  x.isCompleted = data.createAndUpdateSubjects || false
            }
            if(x.value == 'assignAndReassignTeachers' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.assignAndReassignTeachers || false
              })
             // x.isCompleted = data.assignAndReassignTeachers || false
            }
            if(x.value == 'createClass' && this.checkPermission(['V50','VLMP-01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.createClass || false
              })
             // x.isCompleted = data.createClass || false
            }
            if(x.value == 'feePlan' && this.checkPermission(['V50','VFCP01'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.feePlan || false
              })
             // x.isCompleted = data.feePlan || false
            }
            if(x.value == 'publishFeePlans' && this.checkPermission(['V50','VFCP01','VCP'])){
              this.newList.push({
                "label": x.label,
                "value": x.value,
                "link" :  x.link,
                "isCompleted" : data.publishFeePlans || false
              })
              //x.isCompleted = data.publishFeePlans || false
            }
           
          }
          let ssd = this.newList.filter( x => x.isCompleted)

          const percentage = (ssd.length / this.newList.length) * 100;
          percentage.toFixed(2)
          let roundedNumber = Math.ceil(percentage);

          this.percentageValue = roundedNumber

        
          //this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
     
    //   async getOverdueAndForeCastAmount() {
    //     let userData = secureUI.sessionGet("user");
  
    //     if (!userData) {
    //       this.$toasted.error("Please login and do the action");
    //       this.$router.push("/login");
    //     } else {
    //       const response = await ViService.viXGet(
    //         `/dashboard/overdueAndForeCastAmount?academicYear=${this.selectedAcademicYear}`,
    //         userData.token
    //       );
    //       if (response.isSuccess) {
    //         let result = response.data;
    //         if (result) {
    //           this.schoolCounts.foreCastAmount =  result.foreCast || 0;
    //           this.schoolCounts.dueAmount = result.overDue || 0;
    //         }
    //       }
    //     }
    //   },
      
    },
     components: {
      ViSpinner
    },
  };
  </script>
  